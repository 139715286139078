<script setup lang="ts">
import { useQRCode } from '@vueuse/integrations/useQRCode'

const props = defineProps<{
  data?: any
  imgClass?: string
  captionClass?: string
  caption?: string
}>()

const { t } = useI18n()

const qrcode = useQRCode(props?.data, {
  errorCorrectionLevel: 'H',
  margin: 3,
  width: 500,
  // height: 500,
})
</script>

<template>
  <div v-if="props?.data && qrcode" class="mx-auto mt-10">
    <NuxtImg
      v-if="qrcode && checkImage(qrcode)"
      loading="lazy"
      :src="qrcode"
      alt=""
      class="mx-auto mt-12 h-full w-full rounded-lg border p-2 md:mt-0"
      :class="props?.imgClass ?? 'md:h-60 md:w-60'"
    />
    <div class="mt-5 w-full mx-auto text-2xl sm:text-xl text-center text-gray-500">
      <div :class="props?.captionClass ?? 'w-full sm:w-60 mx-auto'">
        {{ props?.caption ?? t('scan_qrcode') }}
      </div>
    </div>
  </div>
</template>
